<template>
  <div class="supplement-info">
    <div class="supplement-info-title">请按照以下指引完成账号实名信息补充</div>
    <div class="steps">
      <RealNameStep :active="2" />
    </div>
    <div class="form-content">
      <div class="account">
        您的账号
        <div class="account-number">
          <span class="current-phone">{{
            $store.state.user_info && $store.state.user_info.mobile
          }}</span>
          <span class="security">
            <img :src="require('assets/images/real-security.png')" alt="" />
            个人账号
          </span>
        </div>
      </div>
      <div class="input-wrap">
        <div class="title must">姓名</div>
        <div class="input-inner">
          <input v-model="yourName" type="text" placeholder="请输入姓名" />
        </div>
      </div>
      <div class="input-wrap">
        <div class="title must">身份证号码</div>
        <div class="input-inner">
          <input
            v-model="IDNumber"
            type="text"
            placeholder="请输入身份证号码"
          />
        </div>
      </div>
      <div class="input-wrap">
        <div class="title">推荐码</div>
        <div class="input-inner">
          <input v-model="recommendationCode" type="text" placeholder="选填" />
        </div>
      </div>
      <div class="agreement">
        <el-checkbox v-model="agreed"></el-checkbox>
        <span
          >我已阅读并同意
          <span class="blue"  @click="openWindowtwo"
            >《写手智能科技版权服务平台用户协议》</span
          >
          <span class="blue"  @click="openWindow"
            >《写手智能科技隐私政策》</span
          >
        </span>
      </div>
      <div class="submit" @click="submit">提交</div>
    </div>
    <!--已实名提示-->
    <el-dialog
      :visible.sync="popAlreadyRealName"
      :modal="false"
      :close-on-click-modal="false"
      class="already-real-name"
    >
      <template #title>
        <div class="title-icon">
          <div @click="clickErrorCorrect">
            <img :src="require('assets/images/mess.png')" alt="" />
            <span>纠错</span>
          </div>
        </div>
      </template>
      <div class="content">
        <img :src="require('assets/images/message-error.png')" alt="" />
        <span>该登记信息已于{{ createTime }}完成实名认证。</span>
      </div>
      <div class="footer">
        <span class="cancellation" @click="clickCancellation"
          >注销原实名账号</span
        >
        <span class="vice-add" @click="clickAddSubAccount"
          >添加为实名副账号</span
        >
      </div>
    </el-dialog>
    <!--注销原实名账号-->
    <el-dialog
      :visible.sync="popCancellation"
      :modal="false"
      :close-on-click-modal="false"
      title="注销原实名账号"
      class="cancellation-origin"
      @close="closeCancellation"
    >
      <div class="content">
        <div class="input-wrap">
          <div class="title must">
            原实名账号手机号码
            <span class="no-longer" @click="clickNoLongerUse"
              >原手机号不再使用</span
            >
          </div>
          <div class="input-inner">
            <input
              v-model="phoneNumber"
              type="text"
              placeholder="请输入原实名账号手机号码"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">手机验证码</div>
          <div class="input-inner">
            <input
              v-model="phoneVerif"
              type="text"
              placeholder="请输入验证码"
            />
            <div class="verif">
              <span class="txt">
                <span v-if="verif_seconds == 60" @click="sendVerif(phoneNumber)"
                  >获取验证码</span
                >
                <span v-if="verif_seconds < 60">{{ verif_seconds }}s</span>
              </span>
            </div>
          </div>
        </div>
        <div class="desc">
          即日起自六个月内，在该账号不再登陆的情况下，将进行销号处理。
          该账号有登陆的情况下，该注销实名账号不成功，需再次提交。
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div
            class="footer-btn cancellation-submit"
            @click="cancellationSubmit"
          >
            提交
          </div>
          <div class="footer-btn cancel" @click="popCancellation = false">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
    <!--原手机不再使用-绑定新手机-->
    <el-dialog
      :visible.sync="popBindingNewPhone"
      :modal="false"
      :close-on-click-modal="false"
      title="绑定新手机号"
      class="new-binding"
      @close="closeNewBinding"
    >
      <div class="content">
        <div class="input-wrap">
          <div class="title must">原手机号码</div>
          <div class="input-inner">
            <input
              v-model="originPhoneNumber"
              type="text"
              placeholder="请输入原手机号码"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">姓名</div>
          <div class="input-inner">
            <input v-model="yourName1" type="text" placeholder="请输入姓名" />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">身份证号码</div>
          <div class="input-inner">
            <input
              v-model="IDNumber1"
              type="text"
              placeholder="请输入身份证号码"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">新手机号码</div>
          <div class="input-inner">
            <input
              v-model="newPhoneNumber"
              type="text"
              placeholder="请输入新手机号码"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">手机验证码</div>
          <div class="input-inner">
            <input
              v-model="phoneVerif"
              type="text"
              placeholder="请输入验证码"
            />
            <div class="verif">
              <span class="txt">
                <span
                  v-if="verif_seconds == 60"
                  @click="sendVerif(newPhoneNumber)"
                  >获取验证码</span
                >
                <span v-if="verif_seconds < 60">{{ verif_seconds }}s</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn new-binding-submit" @click="newBindingSubmit">
            提交
          </div>
          <div class="footer-btn cancel" @click="popBindingNewPhone = false">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
    <!--添加副账号-->
    <el-dialog
      :visible.sync="popAddSubAccount"
      :modal="false"
      :close-on-click-modal="false"
      title="添加实名副账号"
      class="add-sub-account"
      @close="closeAddSubAccount"
    >
      <div class="content">
        <div class="input-wrap">
          <div class="title must">
            实名主账号手机号码
            <span class="no-longer" @click="clickNoLongerUse"
              >原手机号不再使用</span
            >
          </div>
          <div class="input-inner">
            <input
              v-model="masterPhoneNumber"
              type="text"
              placeholder="请输入实名主账号手机号码"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title must">手机验证码</div>
          <div class="input-inner">
            <input
              v-model="phoneVerif"
              type="text"
              placeholder="请输入验证码"
            />
            <div class="verif">
              <span class="txt">
                <span
                  v-if="verif_seconds == 60"
                  @click="sendVerif(masterPhoneNumber)"
                  >获取验证码</span
                >
                <span v-if="verif_seconds < 60">{{ verif_seconds }}s</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn cancellation-submit" @click="subAccountSubmit">
            提交
          </div>
          <div class="footer-btn cancel" @click="popAddSubAccount = false">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
    <!--纠错-上传身份证弹窗-->
    <el-dialog
      :visible.sync="popRecheck"
      :modal="false"
      :close-on-click-modal="false"
      title="纠错"
      class="recheck"
    >
      <div class="content">
        <div class="desc">
          请您重新核对实名信息。您也可以提交签名的身份证复印件。
        </div>
        <div class="title must">提交身份证复印件...</div>
        <div class="upload-id">
          <div class="front">
            <div class="upload-bg" @click="uploadFront">
              <input
                type="file"
                name="frontFile"
                ref="frontFile"
                @click.stop
                @change="changeFrontFile"
              />
              <img v-show="frontFile" :src="uploadedFront" alt="" />
            </div>
            <div class="tips">请上传身份证正面</div>
          </div>
          <div class="back">
            <div class="upload-bg" @click="uploadBack">
              <input
                type="file"
                name="backFile"
                ref="backFile"
                @click.stop
                @change="changeBackFile"
              />
              <img v-show="backFile" :src="uploadedBack" alt="" />
            </div>
            <div class="tips">请上传身份证反面</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn cancellation-submit" @click="recheckSubmit">
            提交
          </div>
          <div class="footer-btn cancel" @click="popRecheck = false">取消</div>
        </div>
      </div>
    </el-dialog>

    
  </div>
</template>

<script>
import RealNameStep from "components/copyright-service/real-name/RealNameStep";
import {
  addRealName,
  cancelRealName,
  updateRealNameMobile,
  addDeputyAccount,
  realNameErrorCorrect,
} from "api/copyrightService";
import {
  getMobileAchCaptchas,
  checkMobileAchCaptchas,
  upload,
} from "api/common";
export default {
  name: "SupplementInfo",
  data() {
    return {
      //姓名
      yourName: "",
      //身份证号
      IDNumber: "",
      //推荐码
      recommendationCode: "",
      //勾选协议
      agreed: false,
      //显示已实名提示弹窗
      popAlreadyRealName: false,
      //显示纠错弹窗
      popRecheck: false,
      //显示注销原实名账号弹窗
      popCancellation: false,
      //显示添加副实名账号弹窗
      popAddSubAccount: false,
      //显示绑定新手机弹窗
      popBindingNewPhone: false,
      //已实名时间
      createTime: "",
      //手机号码
      phoneNumber: "",
      //手机验证码
      phoneVerif: "",
      //验证码计时
      verif_seconds: 60,
      //验证码id
      verif_code: "",
      //计时器id
      verif_timer: null,
      //原手机号
      originPhoneNumber: "",
      //新手机号
      newPhoneNumber: "",
      //绑定新手机，姓名
      yourName1: "",
      //绑定新手机，身份证号
      IDNumber1: "",
      //主账号
      masterPhoneNumber: "",
      //身份证正面文件
      frontFile: null,
      //身份证反面文件
      backFile: null,
      //身份证正面文件值
      frontFileValue: "",
      //身份证反面文件值
      backFileValue: "",
      //上传默认地址
      uploadUrl: "https://fafs.antiplagiarize.com/",
      //上传正面返回的数据
      uploadedFront: "",
      //上传反面返回的数据
      uploadedBack: "",
    };
  },
  methods: {
    // 打开用户协议
    openWindow(){
       window.open("http://opf.antiplagiarize.com/onlinePreview?url=aHR0cDovL2ZhZnMuYW50aXBsYWdpYXJpemUuY29tL2dyb3VwMS9NMDAvMDAvMTcvTDJyY2htSWdZSldBSmhPU0FBR0NLZTk3a0hnNjQuZG9jeA%3D%3D", '_blank'); 
    },
     // 打开用户协议
    openWindowtwo(){
       window.open("http://opf.antiplagiarize.com/onlinePreview?url=aHR0cDovL2ZhZnMuYW50aXBsYWdpYXJpemUuY29tL2dyb3VwMS9NMDAvMDAvMTcvTDJyY2htSWdnd1dBQm1ZbUFBQ25POGt6RmZVMTguZG9jeA%3D%3D", '_blank'); 
    },
    //点击纠错
    clickErrorCorrect() {
      this.popAlreadyRealName = false;
      this.popRecheck = true;
    },
    //点击注销实名
    clickCancellation() {
      this.popAlreadyRealName = false;
      this.popCancellation = true;
    },
    //点击原手机不再使用
    clickNoLongerUse() {
      this.popCancellation = false;
      this.popAddSubAccount = false;
      this.popBindingNewPhone = true;
    },
    //点击添加副实名
    clickAddSubAccount() {
      this.popAlreadyRealName = false;
      this.popAddSubAccount = true;
    },
    //关闭注销实名
    closeCancellation() {
      this.phoneNumber = "";
      this.phoneVerif = "";
    },
    //关闭绑定新手机
    closeNewBinding() {
      this.originPhoneNumber = "";
      this.yourName1 = "";
      this.IDNumber1 = "";
      this.newPhoneNumber = "";
      this.phoneVerif = "";
    },
    //关闭添加副实名
    closeAddSubAccount() {
      this.masterPhoneNumber = "";
      this.phoneVerif = "";
    },
    //检查必填项
    checkRequiredItem() {
      if (!this.yourName) {
        this.$message({
          type: "error",
          message: "请输入您的真实姓名",
        });
        return false;
      }
      let reg_num = /^[A-Za-z0-9]+$/g;

      if (reg_num.test(this.yourName)) {
        this.$message({
          type: "error",
          message: "姓名不能为数字和字母",
        });
        return false;
      }

      if (!this.IDNumber) {
        this.$message({
          type: "error",
          message: "请输入身份证号码",
        });
        return false;
      }
      return true;
    },
    //提交信息
    submit() {
      if (!this.checkRequiredItem()) return;
      let reg_id =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!reg_id.test(this.IDNumber)) {
        return this.$message({
          type: "error",
          message: "您输入的身份证号格式不正确",
        });
      }
      if (!this.agreed) {
        return this.$message({
          type: "error",
          message: "请先阅读并勾选相关协议",
        });
      }
      const params = {
        realnameType: 1,
        idNumber: this.IDNumber,
        cardName: this.yourName,
        recCode: this.recommendationCode,
      };
      addRealName(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
          this.$router.push({
            path: "/copyrightService/realName/realNameSuccess",
          });
          this.$store.dispatch("CHECK_REAL_NAME");
        } else if (res.code == 2002) {
          this.$message({
            type: "warning",
            message: "已实名认证",
          });
          this.createTime = res.data.createTime;
          this.popAlreadyRealName = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    setVerifTime() {
      this.verif_timer = setInterval(() => {
        if (this.verif_seconds > 0) {
          this.verif_seconds--;
        } else {
          clearInterval(this.verif_timer);
          this.verif_seconds = 60;
        }
      }, 1000);
    },
    //发送验证码
    sendVerif(checkPhone) {
      if (!checkPhone) {
        return this.$message({
          type: "error",
          message: "请输入手机号码!",
        });
      }
      if (!/^0{0,1}1[3|4|5|6|7|8]\d{9}$/.test(checkPhone)) {
        return this.$message({
          type: "error",
          message: "请输入正确的手机号码!",
        });
      }
      const data = {
        type: 5,
        mobile: checkPhone,
      };
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.verif_code = res.data.codeId;
          this.setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //注销实名提交信息
    cancellationSubmit() {
      if (!this.phoneNumber) {
        return this.$message({
          type: "error",
          message: "请输入手机号码!",
        });
      }
      if (!this.phoneVerif || !this.verif_code) {
        return this.$message({
          type: "error",
          message: "请先获取验证码!",
        });
      }
      const params = {
        mobile: this.phoneNumber,
        codeId: this.verif_code,
        code: this.phoneVerif,
      };
      //校验验证码是否正确
      checkMobileAchCaptchas(params).then((res) => {
        if (res.code == 200) {
          clearInterval(this.verif_timer);
          this.cancelRealNameSubmit();
          this.verif_seconds = 60;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //注销实名
    cancelRealNameSubmit() {
      const params = {
        mobile: this.phoneNumber,
        realnameType: 1,
        idNumber: this.IDNumber,
        cardName: this.yourName,
      };
      cancelRealName(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "开启注销进程，账号将在未来六个月后注销",
          });
          this.popCancellation = false;
          console.log(res);
        } else {
          this.$message({
            type: "error",
            message: "信息有误，未能注销原实名账号",
          });
        }
      });
    },
    //修改实名认证绑定的手机
    newBindingMobile() {
      const params = {
        oldMobile: this.originPhoneNumber,
        newMobile: this.newPhoneNumber,
        name: this.yourName1,
        idNumber: this.IDNumber1,
      };
      updateRealNameMobile(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "成功绑定新手机号，可以使用新手机号登陆",
          });
          this.popBindingNewPhone = false;
          console.log(res);
        } else {
          this.$message({
            type: "error",
            message: "信息有误，未能绑定新手机号。",
          });
        }
      });
    },
    //绑定新手机提交信息
    newBindingSubmit() {
      if (
        !this.originPhoneNumber ||
        !this.yourName1 ||
        !this.IDNumber1 ||
        !this.newPhoneNumber
      ) {
        return this.$message({
          type: "error",
          message: "请填写必填选项!",
        });
      }
      if (!this.phoneVerif || !this.verif_code) {
        return this.$message({
          type: "error",
          message: "请先获取验证码!",
        });
      }
      const params = {
        mobile: this.newPhoneNumber,
        codeId: this.verif_code,
        code: this.phoneVerif,
      };
      checkMobileAchCaptchas(params).then((res) => {
        if (res.code == 200) {
          clearInterval(this.verif_timer);
          this.newBindingMobile();
          this.verif_seconds = 60;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //添加副实名提交信息
    subAccountSubmit() {
      if (!this.masterPhoneNumber) {
        return this.$message({
          type: "error",
          message: "请输入实名主账号!",
        });
      }
      if (!this.phoneVerif || !this.verif_code) {
        return this.$message({
          type: "error",
          message: "请先获取验证码!",
        });
      }
      const params = {
        mobile: this.masterPhoneNumber,
        codeId: this.verif_code,
        code: this.phoneVerif,
      };
      //校验验证码是否正确
      checkMobileAchCaptchas(params).then((res) => {
        if (res.code == 200) {
          clearInterval(this.verif_timer);
          this.addSubAccount();
          this.verif_seconds = 60;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //添加为实名副账号
    addSubAccount() {
      const params = {
        mobile: this.masterPhoneNumber,
        realnameType: 1,
        idNumber: this.IDNumber,
        cardName: this.yourName,
      };
      addDeputyAccount(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "成功添加为实名副账号",
          });
          this.popAddSubAccount = false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //上传文件
    uploadFile(file) {
      let form = new FormData();
      form.append("file", file.file);
      upload(form).then((res) => {
        if (res.code == 200) {
          if (file.side == "front") {
            this.uploadedFront = this.uploadUrl + res.data;
          } else {
            this.uploadedBack = this.uploadUrl + res.data;
          }
          this.$message({
            type: "success",
            message: "上传成功!",
          });
          // console.log(res);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //选择正面文件
    changeFrontFile() {
      let file = this.$refs["frontFile"].files[0];
      // console.log("change : ",this.$refs["frontFile"].value);
      if (file && file.type.indexOf("image") == -1) {
        return this.$message({
          type: "warning",
          message: "请上传图片",
        });
      }
      //必须是当上次选择了文件，而此时文件域没有文件值，则是取消选择,并且清空文件域的值
      if (this.frontFileValue && !this.$refs["frontFile"].value) {
        return this.$message({
          type: "warning",
          message: "取消选择",
        });
      }
      if (this.$refs["frontFile"].value == this.frontFileValue) {
        return this.$message({
          type: "warning",
          message: "已上传正面图片，请勿重复选择相同的图片",
        });
      } else {
        this.frontFileValue = this.$refs["frontFile"].value;
        this.frontFile = file;
        this.uploadFile({
          file,
          side: "front",
        });
      }
    },
    //选择反面文件
    changeBackFile() {
      let file = this.$refs["backFile"].files[0];
      if (file && file.type.indexOf("image") == -1) {
        return this.$message({
          type: "warning",
          message: "请上传图片",
        });
      }
      if (this.backFileValue && !this.$refs["backFile"].value) {
        return this.$message({
          type: "warning",
          message: "取消选择",
        });
      }
      if (this.$refs["backFile"].value == this.backFileValue) {
        return this.$message({
          type: "warning",
          message: "已上传反面图片，请勿重复选择相同的图片",
        });
      } else {
        this.backFileValue = this.$refs["backFile"].value;
        this.backFile = file;
        this.uploadFile({
          file,
          side: "back",
        });
      }
    },
    //上传身份证正面
    uploadFront() {
      this.$refs["frontFile"].click();
    },
    //上传身份证反面
    uploadBack() {
      this.$refs["backFile"].click();
    },
    //提交上传身份证信息
    recheckSubmit() {
      if (!this.uploadedFront) {
        return this.$message({
          type: "warning",
          message: "请上传身份证正面",
        });
      }
      if (!this.uploadedBack) {
        return this.$message({
          type: "warning",
          message: "请上传身份证反面",
        });
      }
      this.recheckRealName();
    },
    //纠错-重新核对
    recheckRealName() {
      const params = {
        idNumber: this.IDNumber,
        cardName: this.yourName,
        idCardFront: this.uploadedFront,
        idCardBack: this.uploadedBack,
      };
      realNameErrorCorrect(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.data,
          });
          this.popRecheck = false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
  },
  components: {
    RealNameStep,
  },
};
</script>

<style lang="scss" scoped>
//已实名纠错弹窗
::v-deep .el-dialog__wrapper.already-real-name .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 340px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 15px 12px;
    .title-icon {
      @include flex-start;
      > div {
        @include flex-start;
        @include noselect;
        img {
          width: 13px;
          height: 13px;
        }
        span {
          margin-left: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
        cursor: pointer;
      }
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 11px;
    }
  }
  .el-dialog__body {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    padding: 22px 40px 35px;
    .content {
      @include flex-start;
      align-items: normal;
      flex: 1;
      img {
        width: 17.7px;
        height: 17.7px;
      }
      span {
        margin-left: 9px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .footer {
      @include flex-between(space-around);
      @include noselect;
      > span {
        padding: 0 8px;
        font-size: 14px;
        font-weight: 500;
        color: #4587ff;
        cursor: pointer;
      }
    }
  }
}
//注销原实名弹窗
::v-deep .el-dialog__wrapper.cancellation-origin .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 428px;
  height: 420px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 15px 11px 13px 10px;
    background-color: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 18px;
      right: 11px;
    }
  }
  .el-dialog__body {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    padding: 20px 30px;
    .content {
      .input-wrap {
        @include flex-center(column);
        justify-content: normal;
        align-items: flex-start;
        margin-top: 30px;
        .title {
          @include flex-between;
          position: relative;
          width: 100%;
          padding-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          &.must::before {
            content: "*";
            position: absolute;
            top: 3px;
            left: 2px;
            font-size: 14px;
            color: #eb441e;
          }
          .no-longer {
            font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
            color: #999999;
            cursor: pointer;
          }
        }
        .input-inner {
          position: relative;
          width: 100%;
          margin-top: 14px;
          input {
            width: 100%;
            height: 50px;
            padding: 18px 20px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            border: 1px solid #eceff1;
            border-radius: 5px;
            box-sizing: border-box;
            transition: border-color 0.3s;
            &:focus {
              border-color: #4587ff;
            }
          }
          .verif {
            @include flex-center;
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            color: #4588ff;
            &::before {
              content: "";
              position: absolute;
              top: calc(50% - 10px);
              left: 0;
              width: 1px;
              height: 20px;
              background-color: #d9dee6;
            }
            .txt {
              @include noselect;
              cursor: pointer;
            }
          }
        }
        &:first-child {
          margin: 0;
        }
      }
      .desc {
        margin-top: 29px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin-top: 10px;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.cancellation-submit {
            background: #4c8cff;
            border: 1px solid #4587ff;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
//绑定新手机弹窗
::v-deep .el-dialog__wrapper.new-binding .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 428px;
  height: 710px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 15px 11px 13px 10px;
    background-color: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 18px;
      right: 11px;
    }
  }
  .el-dialog__body {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    padding: 20px 30px;
    .content {
      .input-wrap {
        @include flex-center(column);
        justify-content: normal;
        align-items: flex-start;
        margin-top: 24px;
        .title {
          position: relative;
          padding-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          &.must::before {
            content: "*";
            position: absolute;
            top: 3px;
            left: 2px;
            font-size: 14px;
            color: #eb441e;
          }
        }
        .input-inner {
          position: relative;
          width: 100%;
          margin-top: 14px;
          input {
            width: 100%;
            height: 50px;
            padding: 18px 20px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            border: 1px solid #eceff1;
            border-radius: 5px;
            box-sizing: border-box;
            transition: border-color 0.3s;
            &:focus {
              border-color: #4587ff;
            }
          }
          .verif {
            @include flex-center;
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            color: #4588ff;
            &::before {
              content: "";
              position: absolute;
              top: calc(50% - 10px);
              left: 0;
              width: 1px;
              height: 20px;
              background-color: #d9dee6;
            }
            .txt {
              @include noselect;
              cursor: pointer;
            }
          }
        }
        &:first-child {
          margin: 0;
        }
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin-top: 30px;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.new-binding-submit {
            background: #4c8cff;
            border: 1px solid #4587ff;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
//添加副实名弹窗
::v-deep .el-dialog__wrapper.add-sub-account .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 428px;
  height: 370px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 15px 11px 13px 10px;
    background-color: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 18px;
      right: 11px;
    }
  }
  .el-dialog__body {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    padding: 20px 30px;
    .content {
      .input-wrap {
        @include flex-center(column);
        justify-content: normal;
        align-items: flex-start;
        margin-top: 30px;
        .title {
          @include flex-between;
          position: relative;
          width: 100%;
          padding-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          &.must::before {
            content: "*";
            position: absolute;
            top: 3px;
            left: 2px;
            font-size: 14px;
            color: #eb441e;
          }
          .no-longer {
            font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
            color: #999999;
            cursor: pointer;
          }
        }
        .input-inner {
          position: relative;
          width: 100%;
          margin-top: 14px;
          input {
            width: 100%;
            height: 50px;
            padding: 18px 20px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            border: 1px solid #eceff1;
            border-radius: 5px;
            box-sizing: border-box;
            transition: border-color 0.3s;
            &:focus {
              border-color: #4587ff;
            }
          }
          .verif {
            @include flex-center;
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            color: #4588ff;
            &::before {
              content: "";
              position: absolute;
              top: calc(50% - 10px);
              left: 0;
              width: 1px;
              height: 20px;
              background-color: #d9dee6;
            }
            .txt {
              @include noselect;
              cursor: pointer;
            }
          }
        }
        &:first-child {
          margin: 0;
        }
      }
      .desc {
        margin-top: 29px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin-top: 31px;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.cancellation-submit {
            background: #4c8cff;
            border: 1px solid #4587ff;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
//重新核对-上传身份证
::v-deep .el-dialog__wrapper.recheck .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 540px;
  height: 431px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 15px 11px 13px 10px;
    background-color: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 18px;
      right: 11px;
    }
  }
  .el-dialog__body {
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    padding: 20px 30px 19px;
    .content {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      flex: 1;
      .desc {
        font-size: 14px;
        font-weight: 500;
        color: #999999;
      }
      .title {
        position: relative;
        padding-left: 8px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        &.must::before {
          content: "*";
          position: absolute;
          top: 3px;
          left: 2px;
          font-size: 14px;
          color: #eb441e;
        }
      }
      .upload-id {
        @include flex-between;
        margin-top: 20px;
        .front,
        .back {
          .upload-bg {
            width: 220px;
            height: 140px;
            background-size: 100% 100%;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            input {
              display: none;
            }
          }
          .tips {
            margin-top: 27px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
        }
        .front .upload-bg {
          background: url("~assets/images/id-head.png") no-repeat;
        }
        .back .upload-bg {
          background: url("~assets/images/id-national-emblem.png") no-repeat;
        }
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin-top: 40px;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.cancellation-submit {
            background: #4c8cff;
            border: 1px solid #4587ff;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
.supplement-info {
  &-title {
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .steps {
    margin: 39px 0 60px;
  }
  .form-content {
    width: 300px;
    margin: 0 auto;
    .account {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      &-number {
        @include flex-start;
        margin-top: 10px;
        .security {
          @include flex-center;
          width: 75px;
          height: 23px;
          margin-left: 6px;
          background: #f5f9ff;
          border: 1px solid #4587ff;
          font-size: 12px;
          font-weight: 500;
          color: #4587ff;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    .input-wrap {
      @include flex-center(column);
      justify-content: normal;
      align-items: flex-start;
      margin-top: 30px;
      .title {
        position: relative;
        padding-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        &.must::before {
          content: "*";
          position: absolute;
          top: 3px;
          left: 2px;
          font-size: 14px;
          color: #eb441e;
        }
      }
      .input-inner {
        position: relative;
        width: 100%;
        margin-top: 14px;
        input {
          width: 100%;
          height: 45px;
          padding: 10px 15px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          border: 1px solid #eceff1;
          border-radius: 5px;
          box-sizing: border-box;
          transition: border-color 0.3s;
          &:focus {
            border-color: #4587ff;
          }
        }
      }
    }
    .agreement {
      @include flex-start;
      align-items: normal;
      margin-top: 30px;
      > span {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        .blue {
          color: #4587ff;
          cursor: pointer;
        }
      }
    }
    .submit {
      @include noselect;
      height: 40px;
      margin-top: 19px;
      background: #4588ff;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
