<template>
  <div class="real-name-step">
    <div
      :class="[
        'part',
        active == 1 ? 'in-progress' : '',
        active > 1 ? 'is-complete' : '',
      ]"
    >
      <span class="order">1.</span>
      <span class="complete" v-if="active > 1"
        ><img :src="require('assets/images/real-complete.png')" alt=""
      /></span>
      <span class="txt">验证手机</span>
    </div>
    <div :class="['step-line', active > 1 ? 'step-line-active' : '']"></div>
    <div
      :class="[
        'part',
        active == 2 ? 'in-progress' : '',
        active > 2 ? 'is-complete' : '',
      ]"
    >
      <span class="order">2.</span>
      <span class="complete" v-if="active > 2"
        ><img :src="require('assets/images/real-complete.png')" alt=""
      /></span>
      <span class="txt">信息补充</span>
    </div>
    <div :class="['step-line', active > 2 ? 'step-line-active' : '']"></div>
    <div :class="['part', active == 3 ? 'in-progress' : '']">
      <span class="order">3.</span>
      <span class="txt">提交成功</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RealNameStep",
  props: {
    active: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.real-name-step {
  @include flex-between;
  @include noselect;
  position: relative;
  width: 575px;
  height: 27px;
  margin: 0 auto;
  .step-line {
    width: 100px;
    height: 1px;
    background-color: #ededed;
    &.step-line-active {
      background-color: #4587ff;
    }
  }
  .part {
    @include flex-start;
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    .order {
      font-size: 24px;
      font-weight: bold;
      color: #cccccc;
    }
    .complete {
      width: 27px;
      height: 27px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      margin-left: 12px;
    }
    &.in-progress {
      .order {
        color: #4587ff;
      }
      .txt {
        color: #333333;
      }
    }
    &.is-complete {
      .order {
        display: none;
      }
      .txt {
        color: #333333;
      }
    }
  }
}
</style>
